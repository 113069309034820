import React, { useEffect, useRef } from 'react'
import "./About.css"
import menobg from "../../images/menobg.png"
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'


const About = () => {
  const container = useRef(null)
  gsap.registerPlugin(ScrollTrigger)
  useEffect(()=>{
    const el = container.current;
    gsap.fromTo(".about__container",{
      scale:0.7
    },
    {
      scale:1,
      scrollTrigger:{
        trigger:el,
        scrub:true,
      }
    }
  )
  },[])
  return (
    <section id='about' ref={container} dir='rtl'>
      <div className="section__wrapper about__container">
        <div className="me__container blur-effect">
          <div className="photo__container">
            <img src={menobg} alt="" />
          </div>
        </div>
        <div className="section__header">
          <h2 className="primary__title">על - more-ways</h2>
          <h1 className="title">שמי<span className="color__primary">אילון מעיין</span></h1>
          <p className="text__muted description" dir='rtl'>
  "אני מתמחה בפיתוח אתרים ואפליקציות ומקפיד לעבוד בשיתוף פעולה עם מומחים נוספים בתחומים כמו עיצוב, תוכן וחוויית משתמש. אני מאמין שישנן דרכים רבות ומגוונות לגשת לפיתוח מוצלח, ולכן אני משלב צוות מומחים בכל פרויקט כדי להבטיח תוצאה איכותית ומותאמת אישית לדרישות שלכם. בנוסף, במסגרת לימודי התכנות שאני מציע, אנחנו בונים פרויקטים שמשלבים טכנולוגיות מתקדמות עם דגש על חוויית משתמש מצוינת."
</p>


        </div>
      </div>
    </section>
  )
}

export default About
