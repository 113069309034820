import React, { useEffect, useRef } from 'react'
import "./Services.css"
import { FaPaintBrush } from 'react-icons/fa'
import { BsCodeSquare } from 'react-icons/bs'
import { TfiWrite } from 'react-icons/tfi'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const Services = () => {
  const container = useRef(null)
  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    const el = container.current
    gsap.fromTo('.service__head', {
      opacity: 0,
    },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: el,
        }
      }
    )

    gsap.fromTo(".service", {
      y: -50,
      opacity: 0,
    },
      {
        y: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: el,
          start: "-100% bottom",
          end: "bottom 20%",
          scrub: true
        }
      }
    )
  }, [])
  return (
    <section id='services' ref={container}>
      <div className="section__wrapper services__wrapper">
        <div className="section__header center">
          <h2 className="primary__title">אנחנו מציעים לכם</h2>
          <p className="text__muted description">
            אנחנו הופכים את הרעיונות שלכם לפרויקט ווב ייחודי שממלא את הציפיות שלכם ומרשים את הלקוחות שלכם.
          </p>
        </div>

        <div className="services__group">
          <article className="service">
            <div className="service__top">
              <div className="icon__container">
                <FaPaintBrush className="icon" />
              </div>
              <h3 className="title">עיצוב UI/UX</h3>
            </div>
            <div className="service__middle">
              <p className="text__muted description" dir='rtl'>
                אנחנו מתמחים בעיצוב חווית משתמש (UI) וממשקי משתמש (UX) במטרה ליצור אתרים ואפליקציות שמתמקדים בחוויית משתמש חלקה ומרשימה. אנו שואפים ליצירת ממשק קל לשימוש ואסתטי, התואם את צרכי הלקוחות שלכם ומגביר את מעורבותם.
              </p>
            </div>
            <div className="service__bottom">
              {/* <button className="btn btn__primary">קראו עוד</button> */}
            </div>
          </article>
          {/* End ui/ux */}

          <article className="service" style={{ "--color-primary": "var(--color-success)" }}>
            <div className="service__top">
              <div className="icon__container">
                <BsCodeSquare className="icon" />
              </div>
              <h3 className="title">פיתוח אתרים</h3>
            </div>
            <div className="service__middle">
              <p className="text__muted description" dir='rtl'>
                אנחנו מתמחים בפיתוח אתרים מותאמים אישית העונים על הדרישות הטכניות והעיצוביות של הלקוח. אנו בונים אתרים רספונסיביים, יעילים ומהירים, תוך שימוש בטכנולוגיות החדישות ביותר על מנת להבטיח חוויית גלישה מושלמת על כל מכשיר.
              </p>
            </div>
            <div className="service__bottom">
              {/* <button className="btn btn__primary">קראו עוד</button> */}
            </div>
          </article>
          {/* End Web development */}

          <article className="service" style={{ "--color-primary": "blueviolet" }}>
            <div className="service__top">
              <div className="icon__container">
                <TfiWrite className="icon" />
              </div>
              <h3 className="title">יצירת תוכן</h3>
            </div>
            <div className="service__middle">
              <p className="text__muted description" dir='rtl'>
                אנו מספקים שירותי יצירת תוכן איכותי המותאם לקהל היעד שלכם, כולל כתיבת תוכן שיווקי, בלוגים ומאמרים שיבנו את המותג שלכם ויגבירו את הנוכחות הדיגיטלית שלכם. התוכן שאנו יוצרים נועד למשוך את תשומת הלב של הלקוחות שלכם ולהפוך את המסר שלכם לברור ומעניין.
              </p>
            </div>
            <div className="service__bottom">
              {/* <button className="btn btn__primary">קראו עוד</button> */}
            </div>
          </article>
          {/* End content creation */}
        </div>
      </div>
    </section>
  )
}

export default Services
