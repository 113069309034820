import React from 'react';

const Business = () => {
  return (
    <section id="business">
      <div className="section_wrapper">
        <h1 className="primary_title">העסק שלנו - פתרונות תכנות מתקדמים</h1>
        <p className="text__muted description">
          אנו מתמחים במתן פתרונות תכנות מותאמים אישית עבור לקוחות במגוון תחומים. עם ניסיון עשיר בפיתוח אתרים, אפליקציות ופתרונות מבוססי ענן, אנחנו מביאים את הטכנולוגיה המתקדמת ביותר לכל פרויקט, תוך שימת דגש על איכות, יעילות וחוויית משתמש מעולה.
        </p>
        
        <h2>השירותים שלנו</h2>
        <ul className="service_list">
          <li><strong>פיתוח אתרים:</strong> בניית אתרי אינטרנט רספונסיביים ומותאמים לכל סוגי המכשירים.</li>
          <li><strong>פיתוח אפליקציות מובייל:</strong> פיתוח אפליקציות iOS ו-Android מתקדמות עם דגש על חוויית משתמש.</li>
          <li><strong>פתרונות מבוססי ענן:</strong> אינטגרציה עם פלטפורמות ענן לניהול ושמירת מידע בצורה מאובטחת ויעילה.</li>
          <li><strong>תחזוקת מערכות:</strong> שירותי תחזוקה ושדרוג למערכות קיימות, כולל תמיכה טכנית 24/7.</li>
        </ul>

        <h2>היתרונות שלנו</h2>
        <p>
          אנו מאמינים בשיתוף פעולה הדוק עם הלקוחות שלנו ומציעים להם שירות מקצועי ומותאם אישית. המומחיות שלנו בתחום התכנות מאפשרת לנו לספק פתרונות חדשניים וייחודיים, תוך שמירה על עמידה בלוחות זמנים ותקציבים.
        </p>

        <h2>למה לבחור בנו?</h2>
        <p>
          <strong>מקצועיות:</strong> כל פרויקט מטופל על ידי צוות מומחים מיומן ומקצועי.<br />
          <strong>חדשנות:</strong> שימוש בטכנולוגיות המתקדמות ביותר בתחום התכנות והפיתוח.<br />
          <strong>יחס אישי:</strong> אנחנו שואפים להכיר את הלקוח ולספק פתרונות מדויקים לצרכיו.<br />
          <strong>תמיכה מלאה:</strong> אנחנו מספקים תמיכה ושירות לאורך כל חיי הפרויקט, עם פתרונות מותאמים לכל צורך.
        </p>
      </div>
    </section>
  );
};

export default Business;
