import React, { useEffect, useState, useRef } from 'react';
import './Navbar.css';
import { menu } from '../../data';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll'; // Scroll links
import { Link as RouterLink } from 'react-router-dom'; // Router links
import { FaArrowUpRightFromSquare, FaBarsStaggered } from 'react-icons/fa6';
import logo from "../../images/logo.png";
import { FaTimes } from 'react-icons/fa';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos > 145) {
      return setVisible(true);
    }
    return setVisible(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const container = useRef(null);
  gsap.registerPlugin(useGSAP);

  useEffect(() => {
    if (visible) {
      gsap.fromTo(
        '.navbar__container',
        {
          y: -250,
          width: '100%',
        },
        {
          y: 0,
          top: 0,
          zIndex: 100,
        }
      );
    }
  }, [visible]);

  useGSAP(() => {
    const timeline = gsap.timeline();
    timeline.from('.tab__item', { opacity: 0, stagger: 0.5 });
  }, { scope: container });

  return (
    <nav className={`navbar__container ${visible ? 'visible' : ''}`} ref={container} dir="rtl">
      {showSidebar ? <div className="overlay" onClick={() => setShowSidebar(!showSidebar)}></div> : ''}

      <div className="logo__container" onClick={() => scroll.scrollToTop({ duration: 500 })}>
        <img src={logo} alt="Logo" className="logo__image" /> {/* הוספת הלוגו */}
      </div>

      <div className={`tab__group ${showSidebar ? 'show' : ''}`}>
        <span className="icon__container close__btn" onClick={() => setShowSidebar(!showSidebar)}>
          <FaTimes />
        </span>

        {/* קישורים פנימיים עם react-scroll */}
        {menu.map((list, index) => (
          <ScrollLink
            activeClass="active"
            className="tab__item name"
            to={list.id}
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            key={index}
          >
            {list.name}
          </ScrollLink>
        ))}
      </div>

      <div className="nav__buttons__group">
        <FaBarsStaggered className="menu" onClick={() => setShowSidebar(!showSidebar)} />
      </div>
    </nav>
  );
};

export default Navbar;
