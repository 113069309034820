import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaCode } from 'react-icons/fa';
import './FooterNav.css';

const FooterNav = () => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      if (window.innerWidth < 768) { // רק במסכים קטנים
        setIsScrolling(true); // הסתרת התפריט במהלך גלילה

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setIsScrolling(false); // הצגת התפריט לאחר שהגלילה נפסקת
        }, 200);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <footer className={`footer-nav ${isScrolling ? 'hidden' : 'visible'}`}>
      <nav>
        <ul className="flex flex-col lg:flex-row justify-center space-y-4 lg:space-y-0 lg:space-x-8">
          <li className="text-white">
            <Link to="/">
              <FaHome className="text-xl" />
            </Link>
          </li>
          <li className="text-white">
            <Link to="/ProgrammingClass">
              <FaCode className="text-xl" />
            </Link>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

export default FooterNav;
