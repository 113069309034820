import React from 'react'
import "./Contact.css"
import {MdOutlineEmail} from 'react-icons/md'
import { RiMessengerLine } from 'react-icons/ri'
import { BsWhatsapp } from 'react-icons/bs'

const Contact = () => {
  return (
    <section id='contact' dir='rtl'>
      <div className="section__wrapper contact__container">
        <div className="section__header">
          <h2 className="primary__title">צור קשר</h2>
          <p className="text__muted description">
          מוכן לקחת את העסק שלך לשלב הבא? בין אם אתה זקוק לפיתוח אתר חדש, לשדרוג אתר קיים או לייעוץ מומחה בטכנולוגיות פיתוח מתקדמות או פשוט ללמוד איך מתכנטים את החלום שלך ושל לקוחות, אני כאן כדי לעזור. פנה אליי כדי לדון בפרויקט שלך, לשאול שאלות, או פשוט להגיד שלום.
          </p>
        </div>
        <div className="contact__group">
          <div className="contact__options">
            <article className="contact__option">
              <MdOutlineEmail className='contact__icon'/>
              <h3>Email</h3>
              <h5>eylon@more-ways.co.il</h5>
              <a href="mailto:eylon@more-ways.co.il" target='_blank' className='btn'>שלח הודעה</a>
            </article>
            <article className="contact__option">
              <RiMessengerLine className='contact__icon'/>
              <h3>Messenger</h3>
              <h5>More-Ways</h5>
              <a href="https://www.facebook.com/profile.php?id=100094083860874" target='_blank' className='btn'>שלח הודעה</a>
            </article>
            <article className="contact__option">
              <BsWhatsapp className='contact__icon'/>
              <h3>WhatsApp</h3>
              <h5>+972586659111</h5>
              <a href="https://api.whatsapp.com/send?phone=+972586659111" target='_blank' className='btn'>שלח הודעה</a>
            </article>
          </div>

          <form>
            <input type="text" name="name" placeholder='שם שלך' required />
            <input type="email" name="email" placeholder='אימייל שלך' required />
            <textarea name='message' rows={7} placeholder='ההודעה שלך'></textarea>
            <button type='submit' className="btn btn__primary">שלח הודעה</button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Contact
