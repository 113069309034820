import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Header from './components/Header';
import About from './components/About';
import Services from './components/Services';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Testimonial from './components/Testimonial';
import Contact from './components/Contact';
import Footer from './components/Footer';
import FooterNav from './components/FooterNav/FooterNav'; // תפריט תחתון נוסף
import Business from './components/pages/ProgrammingClass'; // עמוד העסק
import ProgrammingClass from './components/pages/ProgrammingClass'; // עמוד חוג התכנות

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />  {/* התפריט העליון מופיע כאן */}
              <Header />
              <About />
              <Services />
              <Skills />
              <Projects />
              <Testimonial />
              <Contact />
              <Footer />
              <FooterNav />  {/* תפריט תחתון נוסף */}
            </>
          }
        />

        <Route
          path="/about"
          element={
            <>
              <About />
              <FooterNav />  {/* תפריט תחתון נוסף */}
            </>
          }
        />
        <Route
          path="/ProgrammingClass"
          element={
            <>
              <ProgrammingClass />  {/* עמוד חוג התכנות ללא Navbar */}
              <FooterNav />  {/* תפריט תחתון נוסף */}
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
